.input-class-first {
  color: #397cdd;
  font-size: 14px;
  font-weight: 600;
  width: 90px;
  border: 1px solid rgba(14, 126, 228, 0.6);
  border-left: 0px;
  border-right: 0px;
  padding: 3px;
  text-align: center;
  height: 40px !important;
  z-index: 9999;
  border-radius: 6px 0px 0px 6px !important;
}

.input-class-last {
  color: #397cdd;
  font-size: 14px;
  font-weight: 600;
  width: 90px;
  border: 1px solid rgba(14, 126, 228, 0.6);
  border-left: 0px;
  padding: 3px;
  text-align: center;
  height: 40px !important;
  z-index: 9999;
  border-radius: 0px !important;
}

.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker-wrapper {
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
}

.input-class-first-2 {
  color: #397cdd;
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  border: 1px solid rgba(14, 126, 228, 0.6);
  border-right: 0px;
  padding: 0px 8px 2px 16px;
  border-radius: 8px 0px 0px 8px;
  text-align: center;
  height: 40px;
  z-index: 9999;
}

.input-class-first-2:focus {
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border: 1px solid rgba(14, 126, 228, 0.6);
  outline: none;
}
