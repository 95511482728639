#react-select-2-listbox {
  z-index: 9999;
}

.ant-picker {
  height: 40px;
  width: 100%;
  border-radius: 8px !important;
  border: 1px solid rgba(14, 126, 228, 0.6) !important;
}

.ant-btn-primary {
  background-color: rgb(24, 144, 255) !important;
}
